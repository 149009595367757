/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 08:52:16
 * @LastEditors: sun.mengmeng
 * @LastEditTime: 2021-09-15 09:07:09
 * @Description: file content
 */
module.exports = {
  title: "PEIMAR",

  fixedHeader: false,

  sidebarLogo: false
};
