<!--
 * @Author: jixuyu
 * @Date: 2021-09-20 19:46:52
 * @LastEditTime: 2022-08-17 17:57:04
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\config\components\setFlex.vue
 * 记得注释
-->
<template>
  <div class="set-config set-flex">
    <div class="info-label" style="margin-bottom: 20px;">
      <p class="label-title">{{ $t("config.setOptimizer") }}</p>
      <p class="label-icon" @click="addForm()" style="margin-top: 10px">
        <el-button class="iconfont icon-plus">{{
          $t("config.NewOptimizer")
        }}</el-button>
      </p>
    </div>

    <div class="block" style="margin-bottom: 20px">
      <el-image 
        ref="myImg"
        style="width: 100px; height: 100px;cursor:pointer;"
        :src="require('@/assets/dev/2_01.jpg')"
        :preview-src-list="[require('@/assets/dev/2_01.jpg'),require('@/assets/dev/2_02.jpg')]"
        @click.stop="handleClickItem"/>

        <el-image 
        ref="myImg1"
        style="width: 100px; height: 100px; margin-left: 10px;cursor:pointer;"
        :src="require('@/assets/dev/2_02.jpg')"
        :preview-src-list="[require('@/assets/dev/2_02.jpg'),require('@/assets/dev/2_01.jpg')]"
        @click.stop="handleClickItem"/>
    </div>

    <ul class="flex-tab">
      <li
        class="tab-item"
        v-for="(item, index) of nbList"
        :key="index"
        @click="nbClick(item, index)"
        :class="[nbActive === index ? 'tab-active' : '']"
      >
        <span class="item-span"
          >{{ $t("config.inverterName") }}{{ item.title }}</span
        >
      </li>
    </ul>
    <ul class="flex-tab flex-margin">
      <li
        class="tab-item"
        v-for="(item, index) of tabsList"
        :key="index"
        @click="tabClick(item, index)"
        :class="[tabActive === index ? 'tab-active' : '']"
      >
        <i
          class="el-icon-close item-icon"
          @click.stop="tabDelete(item, index)"
        ></i>
        {{ item.title }}
      </li>
      <li class="tab-plus" @click="tabPlus">+</li>
    </ul>
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="86px"
      class="configForm flex-margin"
    >
      <div
        v-for="(item, index) of ruleForm.formList"
        :key="index"
        class="collect-item"
      >
        <el-form-item class="item-first">
          <el-select
            v-model="item.selectType"
            class="first-select"
            :placeholder="$t('config.Pselect')"
            @change="changeType(item, index)"
            :disabled="item.disabled"
          >
            <el-option
              v-for="(item, index) in selectType"
              :key="index"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <ul class="item-content">
          <li
            class="item-second"
            v-for="(item1, index1) of item.renderList"
            @change="changeMac(item, item1, index, index1)"
            :key="index1"
          >
            <el-form-item
              class="second-flex"
              label="MAC ID："
              :prop="`formList.${index}.renderList.${index1}.mac`"
              :rules="[
                {
                  required: true,
                  message: $t('config.PMAc'),
                  trigger: ['blur', 'change'],
                  validate: validateComfirmPwd,
                },
              ]"
            >
              <el-tooltip
                effect="light"
                :content="$t('config.MacNotExist')"
                placement="right"
                :disabled="item1.show || item1.disabled"
              >
                <el-input
                  @blur="
                    testMac(
                      item1.mac,
                      item1,
                      `formList.${index}.renderList.${index1}.mac`,
                      item,
                      index
                    )
                  "
                  v-model="item1.mac"
                  @change="getMac(item, item1)"
                  :disabled="item1.disabled"
                  :placeholder="$t('config.PMacIdImage')"
                >
                </el-input>
              </el-tooltip>
              <span v-if="item.selectType === 'double'" class="second-mark">{{
                -(Number(index1) + 1)
              }}</span>
            </el-form-item>
            <div class="el-form-item el-form-item--small">
              <div class="el-form-item__content">
                <el-tooltip :content="$t('config.clickMe')" placement="right" effect="light">
                  <i
                    @click.stop="question"
                    class="iconfont el-icon-question"
                    style="margin-left:5px; color:cornflowerblue;cursor:pointer;"
                  ></i>
                </el-tooltip>
              </div>
            </div>
            <el-form-item
              class="second-flex"
              :label="$t('config.Components')"
              :prop="`formList.${index}.renderList.${index1}.id`"
              :rules="[
                {
                  required: true,
                  message: $t('config.PComponents'),
                  trigger: ['change'],
                },
              ]"
            >
              <!-- 单输入模版 -->
              <el-select
                v-if="item.selectType === 'single'"
                v-model="item1.id"
                class="first-select"
                :disabled="item1.disabled"
               :placeholder="$t('config.Pselect')"
              >
                <el-option
                  v-for="(item2, index2) in typeList"
                  :key="index2"
                  :label="`${item2.Manufacturer===undefined?'--':item2.Manufacturer}(${item2.Modulepower}W)`"
                  :value="item2.Id"
                >
                </el-option>
              </el-select>
              <!-- 双输入模版 -->
              <el-select
                v-else
                v-model="item1.id"
                class="first-select"
                :disabled="
                  index1 == 0
                    ? item1.disabled
                    : item1.disabled && item1.dubleDisabled
                "
                :placeholder="$t('config.Pselect')"
              >
                <el-option
                  v-for="(item2, index2) in typeList"
                  :key="index2"
                  :label="`${item2.Manufacturer===undefined?'--':item2.Manufacturer}(${item2.Modulepower}W)`"
                  :value="item2.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="item-width">
              <div class="width-btn">
                <p
                  class="btn-delete btn-common"
                  @click="deleteItem(item, item1, index, index1)"
                >
                  {{ $t("config.delete") }}
                </p>
                <p class="btn-add btn-common" @click="addForm()">
                  {{ $t("config.insert") }}
                </p>
              </div>
            </el-form-item>
          </li>
        </ul>
      </div>
    </el-form>
    <div class="info-bottom">
      <p class="info-btn" @click="saveData(true)">{{ $t("config.save") }}</p>
    </div>
  </div>
</template>

<script>
import {
  stationDetail,
  testMacId,
  setComponent,
  getComponent,
  delHoney,
  delComponent,
} from "@/api/config";
import ConfigNb from "./configNb";
import * as util from "@/utils";
export default {
  components: { ConfigNb },
  // 定义属性
  data() {
    return {
      strChar: '@',
      maxChar: '@',
      nbActive: 0,
      nbList: [],
      tabActive: 0,
      tabsList: [
        {
          title: this.$t("config.string"),
          name: "1",
          content: "configForm",
          id: "",
          formList: [],
        },
      ],
      map: new Map(),
      // selectType: [
      //   {
      //     name: this.$t('config.Single'),
      //     type: "signle"
      //   },
      //   {
      //     name: this.$t('config.Double'),
      //     type: "double"
      //   }
      // ],
      typeList: [],
      ruleForm: {
        formList: [
          {
            type: "add",
            selectType: "single",
            markId: 0,
            disabled: false,
            renderList: [
              {
                mac: "",
                id: this.typeList!==undefined?this.typeList[0].Id:"",
                mainKey: true,
                show: true,
                disabled: false,
                groupId: "",
              },
            ],
          },
        ],
      },
      newAddForm: [],
      currentIndex: 0,
      isTrueMac: true,
    };
  },
  computed: {
    selectType() {
      return [
        {
          name: this.$t("config.Single"),
          type: "single",
        },
        {
          name: this.$t("config.Double"),
          type: "double",
        },
      ];
   },
   // tabsList () {
   //  return [
   //      {
   //        title: this.$t("config.string"),
   //        name: "1",
   //        content: "configForm",
   //        id: "",
   //        formList: [],
   //      },
   //    ]

   //   }
  },
  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    getMac(data, data1) {
      data1.show = true
      if (data.selectType === "double") {
        data.renderList[1].mac = data1.mac;
      }
    },
    //保存优化器
    saveData(isbtn, tabIndex) {
      if (!this.isTrueMac) {
        this.$message.error(this.$t("config.MacError"));
        return;
      }
      const stationId = util.readSession("stationId");
      if (!stationId) return;

      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const { formList } = this.ruleForm;
          //找到有相同的mac,不给提交
          if(formList===undefined) return;
                    let bool = formList.some((v, i)=>
            formList.some((e, j)=>i!==j && v.renderList[0].mac === e.renderList[0].mac)
          );
          if(bool){
            this.$message.error(this.$t("config.duplicateMacId"));
            return ;
          }

          const inverstId = this.nbList[this.nbActive].id;
          const tab = this.tabsList[this.tabActive];
          formList.forEach((v) => {
            v.disabled = true;
            v.renderList.forEach((item) => {
              item.disabled = true;
            });
          });
          const map = formList.map((v) => {
            //单输入数据结构整合
            if (v.selectType === "single") {
              const item = v.renderList[0];
              return {
                GroupId: item.groupId,
                HoneybeeMac: item.mac,
                HoneybeeOutput: 1,
                twoOutPutFlag: 1,
                PVModuleTypeId: item.id,
              };
            } else {
              //双输入数据结构整合
              const item = v.renderList;
              const art = [];
              item.forEach((el, index) => {
                art.push({
                  GroupId: item[index].groupId,
                  HoneybeeMac: item[index].mac,
                  HoneybeeOutput: index + 1,
                  twoOutPutFlag: 2,
                  //组件id
                  PVModuleTypeId: item[index].id,
                });
              });
              return art;
            }
          });
          const newMap = map.flat();
          const params = {
            groupId: tab.id,
            inverterId: inverstId,
            pvpanelList: newMap,
            stationId: stationId,
          };
          if(newMap && newMap.length>25){//超过25个优化器一组提示一下用户
            this.$confirm(this.$t("config.honeybeeNumOverMaximum"), this.$t('common.hint'), {
              confirmButtonText: this.$t("config.confirm"),
              cancelButtonText: this.$t("config.cancel"),
              type: "info",
            }).then(() => {//确定
              this.savePvpanels(params, isbtn, tabIndex)
            }).catch(() => {});
          }else {
            this.savePvpanels(params, isbtn, tabIndex)
          }
        } else {
          this.$message.error(this.$t("config.Pwrite"));
        }
      });
    },
    savePvpanels(params, isbtn, tabIndex) {
      setComponent(params).then((resp) => {
        if (resp.code === 200) {
          this.newAddForm = [];
          if(tabIndex!==undefined)this.tabsList[tabIndex].id=resp.data;
          this.$message.success(this.$t("config.saveSucess"));
          if(isbtn){
            this.$confirm(this.$t("config.saveToNext"), this.$t('common.warning'), {
              confirmButtonText: this.$t("config.confirm"),
              cancelButtonText: this.$t("config.cancel"),
              type: "warning",
            })
              .then(() => {
                this.$emit("nextStep", 4);
              })
              .catch(() => {});
          }
        }
      });
    },
    /**
     * @description: 数据格式转换
     * @param {*} arr
     * @return {*}
     * @author: JIXUYU
     */
    getNewArr(arr) {
      let newArr = [];
      if(arr===undefined) return newArr;
      arr.forEach((item, i) => {
        let index = -1;
        let alreadyExists = newArr.some((newAddress, j) => {
          if (item.HoneybeeMac === newAddress.HoneybeeMac) {
            index = j;
            return true;
          }
        });
        if (!alreadyExists) {
          newArr.push({
            HoneybeeMac: item.HoneybeeMac,
            twoOutPutFlag: item.twoOutPutFlag,
            HoneyList: [item],
          });
        } else {
          newArr[index].HoneyList.push(item);
        }
      });
      return newArr;
    },

    /**
     * @description: 回显示每个优化器组串
     * @param {*} id
     * @return {*}
     * @author: JIXUYU
     */
    showCompoents(id) {
      this.strChar='A';
      const stationId = util.readSession("stationId");
      const params = {
        stationId: stationId,
        inverterId: id,
      };
      getComponent(params).then((resp) => {
        if (resp.code === 200) {
          const tablist = [];
          if(resp.data!==undefined && resp.data!==null && resp.data.length>0){
            resp.data.forEach((v) => {
              const artList = this.getNewArr(v.honeybeeList);
              const formList = artList.map((item, index) => {
                //回显时判断单双输入
                if (item.twoOutPutFlag === 1) {
                  return {
                    type: "add",
                    selectType: "single",
                    markId: index,
                    disabled: true,
                    renderList: [
                      {
                        mac: item.HoneyList[0].HoneybeeMac,
                        id: item.HoneyList[0].PVModuleTypeId,
                        groupId: item.HoneyList[0].GroupId,
                        mainKey: true,
                        show: true,
                        disabled: true,
                      },
                    ],
                  };
                } else {
                  //双输入数据回显

                  return {
                    type: "add",
                    selectType: "double",
                    markId: index,
                    disabled: true,
                    renderList: [
                      {
                        mac: item.HoneyList[0].HoneybeeMac,
                        id: item.HoneyList[0].PVModuleTypeId,
                        mainKey: true,
                        groupId: item.HoneyList[0].GroupId,
                        show: true,
                        disabled: true,
                      },
                      {
                        mac: item.HoneyList[1].HoneybeeMac,
                        id: item.HoneyList[1].PVModuleTypeId,
                        groupId: item.HoneyList[1].GroupId,
                        mainKey: true,
                        show: true,
                        disabled: true,
                        dubleDisabled: true,
                      },
                    ],
                  };
                }
              });
              const json = {
                title: this.$t("config.string") + `${v.Label}`,
                name: v.Label,
                content: "configForm",
                id: v.Id,
                formList: formList,
              };
              tablist.push(json);

              let num = util.letterToNumber(v.Label);
              let maxNum = util.letterToNumber(this.maxChar);
              if(num>maxNum) this.maxChar = v.Label;
            });
          }
          this.strChar = this.maxChar;

          const tem = {
            type: "add",
            selectType: "single",
            markId: 0,
            disabled: false,
            renderList: [
              {
                mac: "",
                id: this.typeList!==undefined?this.typeList[0].Id:"",
                mainKey: true,
                show: true,
                disabled: false,
                groupId: "",
              },
            ],
          };
          if(tablist.length>0){
            this.ruleForm.formList =tablist[0].formList;
            this.newAddForm = [];
            this.tabsList =tablist;
          }else{
            this.currentIndex = 0;
            this.ruleForm.formList =[tem];
            this.newAddForm = [tem];
            this.strChar = util.numberToLetter(util.letterToNumber(this.strChar)+1);
            this.tabsList =[
                  {
                    title: this.$t("config.string")+this.strChar,
                    name: "1",
                    content: "configForm",
                    id: "",
                    formList: [tem],
                  },
                ];
          }
        }
      });
    },
    //逆变器点击
    nbClick(item, index) {
      console.log(this.newAddForm);
      if (this.newAddForm.length>0) {
        this.$confirm(this.$t("config.beSaveString"), this.$t('common.warning'), {
          confirmButtonText: this.$t("config.confirm"),
          cancelButtonText: this.$t("config.cancel"),
          type: "warning",
        })
          .then(() => {
            this.$refs["ruleForm"].validate((valid) => {
              if (valid) {
                this.saveData();
                this.nbActive = index;
                this.showCompoents(item.id);
                this.tabActive = 0;
              } else {
                this.$message.error(this.$t("config.Pwrite"));
              }
            });
          })
          .catch(() => {});
      }else {
        this.nbActive = index;
        this.showCompoents(item.id);
        this.tabActive = 0;
      }
    },
    //组串点击
    tabClick(item, index) {
      console.log(this.newAddForm);
      if (this.newAddForm.length>0) {
        this.$confirm(this.$t("config.beSaveString"), this.$t('common.warning'), {
          confirmButtonText: this.$t("config.confirm"),
          cancelButtonText: this.$t("config.cancel"),
          type: "warning",
        })
          .then(() => {
            this.$refs["ruleForm"].validate((valid) => {
              if (valid) {
                this.saveData(false, this.tabActive);
                this.tabActive = index;
                this.ruleForm.formList = item.formList;
              } else {
                this.$message.error(this.$t("config.Pwrite"));
              }
            });
          })
          .catch(() => {});
      }else {
        this.tabActive = index;
        this.ruleForm.formList = item.formList;
      }
   },
    /**
     * @description: 新增组串
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
   tabPlus () {
     console.log(this.newAddForm);
     if (this.newAddForm.length>0) {
       this.$confirm(this.$t("config.beSaveString"), this.$t('common.warning'), {
         confirmButtonText: this.$t("config.confirm"),
         cancelButtonText: this.$t("config.cancel"),
         type: "warning",
       })
         .then(() => {
            this.$refs["ruleForm"].validate((valid) => {
              if (valid) {
                this.saveData(false, this.tabActive);
                this.tabadd();
              } else {
                  this.$message.error(this.$t("config.Pwrite"));
                }
              });
         })
         .catch(() => {});
     } else {
      this.tabadd();
     }
    },
    tabadd(){
      this.currentIndex =0;
      const json = {
        type: "add",
        selectType: "single",
        markId: this.currentIndex,
        disabled: false,
        renderList: [
          {
            mac: "",
            id: this.typeList!==undefined?this.typeList[0].Id:"",
            mainKey: true,
            show: true,
            disabled: false,
            groupId: "",
          },
        ],
      };
      this.newAddForm=[json];

      this.strChar = util.numberToLetter(util.letterToNumber(this.strChar)+1);
       const obj = {
         title: this.$t("config.string")+this.strChar,
         content: "configForm",
         id: "",
         row: null,
         formList: [json]
       };
       this.tabsList.push(obj);
       this.tabActive = this.tabsList.length-1;
       this.ruleForm.formList = obj.formList;
    },
    //删除组串
    tabDelete(item, index) {
      if (item.id) {
        const stationId = util.readSession("stationId");
        this.$confirm(this.$t("config.beDetelString"), this.$t('common.warning'), {
          confirmButtonText: this.$t("config.confirm"),
          cancelButtonText: this.$t("config.cancel"),
          type: "warning",
        })
          .then(() => {
            const params = {
              groupId: item.id,
              stationId: stationId,
            };
            delComponent(params).then((resp) => {
              if (resp.code === 200) {
                this.deleteTabElement(index);
              }
            });
          })
          .catch(() => {});
      } else {
        this.deleteTabElement(index);
      }
    },
    deleteTabElement(index){
      console.log(this.newAddForm);
      this.newAddForm = [];

      this.tabsList.splice(index, 1);//先删，长度少了一位
      if(index < this.tabActive){ //选中的tab在index的右边，删除index的tab,选中的左移一位
        this.tabActive-=1;
        this.ruleForm.formList = this.tabsList[this.tabActive].formList;
      }else if(index == this.tabActive){//删除选中的tab在，
        if(index === 0){//删除第一个tab
          if(this.tabsList.length<=0){//最后一个了, 清空
            this.ruleForm.formList = [];
          }else {//不是最后一个，选中新的第一个tab
            this.ruleForm.formList = this.tabsList[this.tabActive].formList;
          }
        }else if(index === this.tabsList.length){//删除的是最后一个tab，选中的tab前移一位
            this.tabActive-=1;
            this.ruleForm.formList = this.tabsList[this.tabActive].formList;
        }else {//删除中间的tab，选中的tab序号不变，
          this.ruleForm.formList = this.tabsList[this.tabActive].formList;
        }
      }
    },
    /**
     * @description: 回显电站设置
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    getStationDetail() {
      const stationId = util.readSession("stationId");
      //  const stationId = util.readSession("addStation") === true ? null : util.readSession("userInfo").stationId;
      if (!stationId) return;
      const params = {
        stationId: stationId,
        step: 4,
      };
      stationDetail(params).then((resp) => {
        if (resp.code === 200) {
          //逆变器-组串集合-组件集合
          if (resp.data) {
            const { inverters, pvmoduletypes, groups } = resp.data;
            this.typeList = pvmoduletypes===undefined?[]:pvmoduletypes;
            if (inverters!==undefined && inverters.length > 0) {
              this.nbList = [];
              this.nbList = inverters.map((v, index) => {
                return {
                  title: v.Label,
                  name: `${index + 1}`,
                  content: "configNb",
                  id: v.Id,
                  row: v,
                };
              });
              this.showCompoents(inverters[0].Id);
            }

            if(groups!==undefined && groups.length>0){
              groups.forEach((v,i)=>{
                let num = util.letterToNumber(v.Label);
                let maxNum = util.letterToNumber(this.maxChar);
                if(num>maxNum) this.maxChar = v.Label;
              });
              this.strChar = this.maxChar;
            }
          }
        }
      });
    },
    validateComfirmPwd(rule, value, callback) {
      const params = {
        macId: value,
      };
      testMacId(params).then((resp) => {
        if (!resp.data) {
          item.show = false;
          callback(new Error(this.$t("config.paswordRule")));
          this.$message.error(`'${value}' ${this.$t("config.resetMacId")}`);
          this.$refs["ruleForm"].validateField([prop], (errorMessage) => {});

          setTimeout(function(){
            item.show = true;
          },5000);
          
        } else {
          item.show = true;
          callback();
        }
      });
    },
    //验证macId是否合法
    testMac(mac, item1, prop, item, index) {
      item1.show = true;
      if(mac===undefined || mac==='') {
        this.$refs["ruleForm"].validateField([prop], (errorMessage) => {});
        return ;
      }
      const params = {
        macId: mac,
      };
      testMacId(params).then((resp) => {
        if (!resp.data) {
          item1.show = false;
          this.isTrueMac = false;
          this.$message.error(`'${mac}' ${this.$t("config.resetMacId")}`);
          this.$refs["ruleForm"].validateField([prop], (errorMessage) => {});
          
          setTimeout(function(){
            item1.show = true;
          },5000);

        } else {
          item1.show = true;
          this.isTrueMac = true;
          if(util.isDoubleHoneybee(mac) && item.selectType!=='double'){
            item.selectType='double'
            this.changeType(item, index)
          }else if(!util.isDoubleHoneybee(mac) && item.selectType!=='single'){
            item.selectType='single'
            this.changeType(item, index)
          }
        }
      });
    },
    //新增表单
    addForm() {
      this.currentIndex += 1;
      const json = {
        type: "add",
        selectType: "single",
        markId: this.currentIndex,
        disabled: false,
        renderList: [
          {
            mac: "",
            id: this.typeList!==undefined?this.typeList[0].Id:"",
            mainKey: true,
            show: true,
            disabled: false,
            groupId: "",
          },
        ],
      };
      this.newAddForm.push(json);
      this.ruleForm.formList.push(json);
    },
    //删除
    deleteItem(item, item1, index, index1) {
      console.log(item1);
      const stationId = util.readSession("stationId");
      if (item1.mac) {
        const comId = this.tabsList[this.tabActive].id;
        if (comId) {
          this.$confirm(this.$t("config.beDetelIntervl"), this.$t('common.warning'), {
            confirmButtonText: this.$t("config.confirm"),
            cancelButtonText: this.$t("config.cancel"),
            type: "warning",
          })
            .then(() => {
              if(item.disabled){//新建还没有保存的，直接页面删除，不用提交到服务器 
                const params = {
                  groupId: comId,
                  macId: item1.mac,
                  stationId: stationId,
                };
                delHoney(params).then((resp) => {
                  if (resp.code === 200) {
                    this.ruleForm.formList.splice(index, 1);
                  }
                });
              }else {
                this.ruleForm.formList.splice(index, 1);
              }
            })
            .catch(() => {});
        } else {
          this.ruleForm.formList.splice(index, 1);
        }
      } else {
        this.ruleForm.formList.splice(index, 1);
      }
      this.newAddForm=this.newAddForm.filter((v) => v.markId !== item.markId);
    },
    //选择不同类型
    changeType(item, index) {
      if (item.selectType === "double") {
        const json = {
          id: "",
          component: "",
          mainKey: false,
          disabled: true,
          mac: item.renderList[0].mac,
          id: item.renderList[0].id,
          show: true,
          groupId: "",
          dubleDisabled: false,
        };
        this.ruleForm.formList[index].renderList.push(json);
      } else {
        const json = {
          id: "",
          component: "",
          mainKey: true,
          mac: item.renderList[0].mac,
          id: item.renderList[0].id,
          show: true,
          groupId: "",
        };
        this.ruleForm.formList[index].renderList = [json];
      }
    },
    //修改双输入macId
    changeMac(item, item1, index, index1) {
      // const a = this.ruleForm.renderList[index]
      this.ruleForm.formList[index].renderList[index1].mac = item1.mac;
    },
    handleClickItem(){
      this.$nextTick(()=>{
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
            // 点击遮罩层时调用关闭按钮的 click 事件
            document.querySelector(".el-image-viewer__close").click();
        });
      })
    },
    handleClickItem1(){
      this.$nextTick(()=>{
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
            // 点击遮罩层时调用关闭按钮的 click 事件
            document.querySelector(".el-image-viewer__close").click();
        });
      })
    },
    question(){
      this.$refs.myImg.clickHandler();
      this.handleClickItem();
    }
  },
  created() {
    this.getStationDetail();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
