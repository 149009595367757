<template>
  <div class="login-container">
    <div class="center">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="off"
        label-position="left"
      >
        <div class="title-container">
          <div style="text-align: center;padding-bottom: 18px"><img :src="require('@/assets/login/logo.png')" alt=""
                                                                    width="100px"></div>
          <h3 class="title">{{ $t('login.title') }}</h3>
        </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="Username"
          name="username"
          auto-complete="off"
          type="text"
          tabindex="1"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          placeholder="Password"
          name="password"
          auto-complete="off"
          tabindex="2"
          @keyup.enter.native="handleLogin()"
        />
        <span class="show-pwd" @click="showPwd">
          <svg-icon
            :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
          />
        </span>
        </el-form-item>
        <el-button
          :loading="loading"
          type="success"
          style="width:40%;margin-bottom:10px;"
          @click.native.prevent="handleLogin(1)"
        >{{ $t('login.login') }}
        </el-button
        >
        <el-button
          :loading="demoLoading"
          type="success"
          style="width:40%;margin-bottom:10px;float:right;"
          @click="handleRegister"
        >{{ $t('login.register') }}
        </el-button
        >
        <div class="tips">
          <!--            <span style="margin-right:20px;" @click="handleRegister">{{ $t('login.register') }}</span>-->
          <span @click="forgetPassword">{{ $t('login.Forgot') }}</span>
        </div>

      </el-form>
    </div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      style="min-width:100px;"
    >
      <components :is="componentName" :props="dialog"/>
    </el-dialog>

  </div>
</template>

<script>
import { validUsername } from "@/utils/validate";
import password from "./components/password";

export default {
  name: "Login",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("The password can not be less than 6 digits"));
      } else {
        callback();
      }
    };
    return {
      dialog: {
        title: "",
        visible: false
      },
      componentName: "password",
      loginForm: {
        username: "", //eric
        password: "" //eric123456
      },
      loginRules: {
        username: validUsername,
        password: [
          { required: true, trigger: "blur", validator: validatePassword }
        ]
      },
      loading: false,
      demoLoading: false,
      passwordType: "password",
      redirect: undefined
    };
  },
  components: {
    password
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    /**
     * @description: 忘记密码操作
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    forgetPassword() {
      this.dialog.visible = true;
    },
    /**
     * @description: 跳转注册
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    handleRegister() {
      this.$router.push("/register");
    },

    /**
     * @description:
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    /**
     * @description: 用户登录
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    handleLogin(val) {
     if(val==2){
      this.loginForm.username='demo';
      this.loginForm.password='123456';
     }
     
      this.$refs.loginForm.validate(async valid => {
        try {
          if (valid) {
            this.loading = true;
            this.$store.dispatch("user/login", this.loginForm).then(()=>{
              this.loading = false;
              this.$router.push({ path: "/stationReport" || "/" });
            }).catch(()=>{
              this.loading = false;
            });
            /* setTimeout(() => {
              this.$router.push({ path: "/stationReport" || "/" }).catch(error => {
                console.log(error);
              });
            }, 300); */
          } else {
            console.log("error submit!!");
            return false;
          }
        } catch (error) {
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "./style/index";
</style>
<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  //background: url("~@/assets/login/bg.png") no-repeat;
  background: #103c61;
  background-size: 100% 100%;
  overflow: hidden;


  //width: 380px;
  //height: 350px;
  .center{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .login-form {
    //position: relative;
    width: 402px;
    max-width: 100%;
    padding: 35px 45px;
    float: right;
    right: 5%;
    top: 130px;
    overflow: hidden;
    background: rgba(58, 58, 58, 0.3);
    backdrop-filter: blur(8px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 1px;
    box-sizing: border-box;
    border-radius: 1px;
  }
  .lang {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .tips {
    text-align: left;
    width: 100%;
    font-size: 14px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 0px;

    span {
      cursor: pointer;
      color: #fff;
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .title-container {
    position: relative;
    .title {
      font-family: Source Han Sans CN;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 36px;
      color: #fff;
      margin-bottom: 10px;
      text-align: center;
      font-weight: bold;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .scodw {
    width: 100%;
    img {
      float: left;
      margin-right: 20px;
    }
    p {
      font-family: Source Han Sans CN;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      text-align: left;
      line-height: 19px;
      color: #e8e0e0;
    }
  }
}

* {
  margin: 0;
  border: 0;
  padding: 0;
}

.header {
  margin: 0 auto;
  width: 600px;
  height: 80px;
  background: deeppink;
  /*左右居中*/
  text-align: center;
  /*和上面一样高就上下居中了*/
  line-height: 80px;
}

.body {
  margin: 0 auto;
  width: 600px;
  height: 400px;
  background: #3F536E;
  /*为内部容器的绝对定位做坐标，如果没有这，会以最顶层容器做标准*/
  position: relative;
}

.center {
  width: 380px;
  height: auto;
  background: #113255;
  /*下面四行都是为上下左右居中做的*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.footer {
  margin: 0 auto;
  width: 600px;
  height: 60px;
  background: #2C405A;
}

</style>
