<!--
 * @Author: jixuyu
 * @Date: 2021-09-24 23:09:24
 * @LastEditTime: 2022-05-28 09:18:01
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\status\components\honeyComponent.vue
 * 记得注释
-->
<template>
  <div class="bee-component">
    <GnGrid
      ref="grid"
      :url="getRoleListsAPI"
      :params="gridOptions"
      style="width: 100%"
      :render-content="gridRenderContent"
      class="UserRoles-grid"
      :bodyParser="bodyParser"
    />
  </div>
</template>

<script>
export default {
  components: {},
  // 定义属性
  data() {
    return {
      gridOptions: {},
      bodyParser: false,
      getRoleListsAPI: "/status/honeybee"
    };
  },
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    gridRenderContent() {
      const lang = localStorage.getItem('lang');
      const isZh = lang ? lang.startsWith('zh') : false;
      return [
        { label: "MACID", prop: "id" },
        { label: this.$t('status.SummaryId'), prop: "beehivemacid" },
        { label: this.$t('status.PositionCode'), prop: "location" },
        {
          label: this.$t('status.voltage'),
          render: scope => scope.row.onStatus == 0 ? scope.row.involtage1 : ''
        },
        {
          label: this.$t('status.current'),
          render: scope => scope.row.onStatus == 0 ? scope.row.incurrent1 : ''
        },
        {
          label: this.$t('status.power'),
          render: scope => scope.row.onStatus == 0 ? scope.row.power1 : ''
        },
        { label: this.$t('status.updateTime'), prop: "lasttranstime" },
        {
          label:this.$t('status.status'),
         render: scope => <span class={scope.row.onStatus == 0?'bee-green':'bee-status'}>{scope.row.onStatusName}</span>
        },
        {
          label: this.$t('status.warnCode'),
          render: scope => parseInt(scope.row.warnCode, 16).toString(2).padStart(32, '0').substring(23, 24)==1 ? 'OFF' : 'ON'
        }
      ];
    }
  },


  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
