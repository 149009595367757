//写一个默认输出LayoutLogicHoneybee类
import * as util from "@/utils";
import current from "element-ui/packages/table/src/store/current";
import LayoutLogicBase from "@/logic/LayoutLogicBase";

export default class LayoutLogicInverter extends LayoutLogicBase{
  constructor(canvas) {
    super(canvas);

    //layout设置的宽度和高度 45*67
    // this.width = 80;//44
    // this.height = 40;//66
    // this.canvas = canvas;

    this.name = 'LayoutLogicInverter';
  }

  //显示逆变器
  draw(json) {
    // console.log("json",json);
    const { Xaxis, Yaxis, Label, Id, Angle ,zoom} = json;
    const id = util.generateId(); //生成唯一标识
    fabric.Image.fromURL(
      require("@/assets/design/inverter.svg"),
      (img) => {
        img.set({
      width: this.inverter.width,
      height: this.inverter.height,
      left: Xaxis,
      top: Yaxis,
      originX: "center",
      originY: "center",
          // fill: "#339966",
          // stroke: "#808080",
          // strokeWidth: 1,
      tagName: Label,
      lineWidth: 1,
          markId: id,
      inverterId: Id,
      type: "inverter",
      angle: Angle ? Angle : 0,
      scaleX: zoom,
      scaleY: zoom
    });
    const text = `${Label}`;
        const tagText = new fabric.Text(text, {
          width: 80,
          height: 50,
          left: Xaxis,
          top: Yaxis,
          fontSize: 8,
          markId: id,
          fill: "#fff",
          originX: "center",
          originY: "center",
          scaleX: zoom,
          scaleY: zoom,
        });
    const group = this.group(
          img,
      tagText,
      Id,
      "inverter",
      false
    );
    this.canvas.add(group);
      },
      { crossOrigin: "Anonymous" }
    );
  }

  /**
   * groups数据格式如下
   * {
   * 			"Id": 1,
   * 			"Label": "A",
   * 			"InverterId": 1
   * 		}, {
   * 			"Id": 2,
   * 			"Label": "B",
   * 			"InverterId": 2
   * 		}, {
   * 			"Id": 3,
   * 			"Label": "C",
   * 			"InverterId": 3
   * 	}]
   * @description: 计算inverters位置
   * @param groups
   * @returns {{inverters_height: number, inverters_top: number, inverters_width: number, inverters_left: number}}
   */
  position(groups,pvList) {
    //计算设备总的宽度和高度
    // let {devices_width,devices_height} = this.maxGroupLengthAndHeight(pvList);
    let zoom = this.calcZoom(pvList);

    //计算出canvas中心点坐标
    // let {x,y} = this.calcCenterPoint();
    // console.log("x,y",x,y);

    // console.log(devices_width,devices_height);
    // console.log(this.canvas.width,this.canvas.height);
    // console.log(zoom);
    // console.log(devices_width*zoom,devices_height*zoom);

    // let inverters_width = this.inverter.width * groups.length + (groups.length - 1) * this.inverter_to_inverter_distance;
    //计算出第一个inverter的坐标
    // let first_inverter_x = x-(devices_width*zoom)/2;
    // let first_inverter_y = y-(devices_height*zoom)/2;
    // console.log(first_inverter_x,first_inverter_y);
    // if(first_inverter_x < this.inverter.width*zoom/2){
    //   first_inverter_x = this.inverter.width*zoom/2;
    // }
    //计算出多少个inverters
    let {first_inverter_x,first_inverter_y} = this.firstInverterPoint(pvList);
    console.log(first_inverter_x,first_inverter_y);

    //将数组按照InverterId分组
    const group = groups.reduce((prev, cur) => {
      (prev[cur.InverterId] = prev[cur.InverterId] || []).push(cur);
      return prev;
    }, {});
    //将groups数组对象，先按照InverterId排序，InverterId是数字，如果InverterId相同，
    // 再按照Label排序，其中Label的值是ABCDEF二十六个字母，如果26个字母用完了的话，就是AA、AB以此循环
    const groupList = groups.sort((a, b) => {
      if (a.InverterId === b.InverterId) {
        return a.Label > b.Label ? 1 : -1;
      } else {
        return a.InverterId > b.InverterId ? 1 : -1;
      }
    });

    //计算出每个inverter有多少个group的分组
    let count_groups_per_inverter = Object.keys(group).map((item) => {
      let length = group[item].length;
      return length;
    });

    //循环count_groups_per_inverter数组，计算出每个inverter的坐标
    let inverters = [];
    let inverter_x = first_inverter_x;
    let inverter_y = first_inverter_y;
    inverters.push({x:inverter_x,y:inverter_y});
    // let tmp = 0;
    for (let i = 1; i < count_groups_per_inverter.length; i++) {
      inverter_y += count_groups_per_inverter[i-1] * (this.honeybee.height + this.honeybee_to_honeybee_vertical_distance) * zoom;

      let inverter = {
        x: inverter_x,
        y: inverter_y
      };
      inverters.push(inverter);
      // inverter_x = inverter_x + this.inverter.width + this.inverter_to_inverter_distance;
    }
    return inverters;
    // count_groups_per_inverter.map((item) => {
    //
    // },{first_inverter_x,first_inverter_y});




    // let inverters_width = 100;
    // let inverters_height = honeybees_height;
    // let inverters_left = honeybees_width;
    // let inverters_top = 0;
    // return {inverters_width,inverters_height,inverters_left,inverters_top};
  }
}
