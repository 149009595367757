<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 09:49:51
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-02 18:01:46
 * @Description: file content
-->
<template>
  <div class="TopBar">
    <div class="topbar-left">
      <span class="left-front"><img
        class="logo"
        :src="avatar ? avatar : require('@/assets/login/logo.png')"
      />｜</span
      ><span class="left-back">{{stationName}}</span>
    </div>
    <div class="topbar-right">
      <div class="right-lang">
        <lang class="lang"/>
      </div>
      <p class="right-line"/>
      <AvatarName/>
    </div>
  </div>
</template>

<script>
/**
 * @author: sunmengmeng
 * @since: 2021-09-14
 * @router('/TopBar')
 */
import { mapGetters } from "vuex";
import AvatarName from './Avatar'
import * as util from "@/utils/index";
export default {
  name: "TopBar",
  components: {AvatarName},
  props: {},
  data() {
    return {
    }
  },

  computed: { ...mapGetters(["sidebar", "avatar", "stationName"]) },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    }
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
.TopBar {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  .topbar-left {
    // flex: 1;
    width: 70%;
    display: flex;
    align-items: center;
    padding-left: 18px;
     white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    .left-front {
      color: #fff;
      font-size: 18px;

      .logo{
        width: 200px;
        height: 20px;
        margin-right: 5px;
        margin-bottom:-3px;
      }
    }
    .left-back {
      color: #FFFFFF;
      font-style: italic;
      font-size: 20px;
      margin-left:6px;
    }
  }
  .topbar-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .right-lang {
      display: flex;
      align-items: center;
      height: 60px;

    }
    .right-line {
      width: 1px;
      height: 40px;
      background: #dddfe5;
      margin-left:15px;
    }

    .right-avatar {
      display: flex;
      align-items: center;

      .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #dddfe5;
        margin: 0 18px;
      }
      .user-logout {
        color: #70a436;
        font-size: 16px;
        margin-right: 18px;
        cursor: pointer;
      }
      .user-name {
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>
