<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 08:52:16
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-04-09 19:59:28
 * @Description: file content
-->
<template>
  <div id="app">
    <router-view  v-if="isShow"/>
  </div>
</template>

<script>
export default {
  name: "App",
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isShow:true
    }
  },
  methods:{
    reload(){
      this.isShow=false;
      this.$nextTick(()=>{
        this.isShow=true
      })
    }
  }

};
</script>

<style>
  section.el-drawer__body{
    overflow: auto !important;
  }
  .el-message-box{
    width: 72% !important;
    min-width: 240px;
    max-width: 420px;
  }
</style>
